import React from 'react'
import styled from 'styled-components';

const IntroDM = () => {
    return (
        <Container>
            <ContainerHeader>Digital Marketing</ContainerHeader>
            <ContainerP>"Its hard to find things that wont sell online" - Jeff Bezos</ContainerP>
        </Container>
    )
}

export default IntroDM

const Container = styled.div`
    margin-top: 2rem;
    height: 30vh;
`
const ContainerHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem; 
    text-align: center;
`
const ContainerP = styled.p`
    text-align: center;
    font-size: clamp(1rem,3vw, 1.25rem); 
    padding: 0rem 1rem;
    margin-bottom: 2rem;
`
