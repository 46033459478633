import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Body from '../components/digitalMkt/Body'

const DigitalMarketing = () => (
    <Layout>

        <SEO title="Digital marketing kenya - Marketing agency in kenya"
            description="Digital marketing for healthcare businesses, we help on SEO, social media marketing, email marketing, online advertisement, content marketing, paid ads."
            keywords="digital marketing in kenya, advertising agency in kenya, social media management, marketing agencies in kenya, digital marketing companies in kenya, Digital marketing in healthcare kenya, marketing healthcare in kenya, medical marketing, medical practice marketing, affordable digital marketing, email marketing, search engine optimization kenya, seo kenya, 
                    healthcare content marketing agency, online marketing kenya"
            img="https://www.tripleaimsoftware.com/static/1bb1a21c5b209518e926fda093ea68c7/ee604/HeroDM.png"
            siteUrl="https://www.tripleaimsoftware.com/digital-marketing/">
        </SEO>
        <Body />
    </Layout>
)

export default DigitalMarketing
