import React from 'react'
import styled from 'styled-components';
import { Button } from './Button';

const Request = () => {
    return (
        <ProposalContainer>
            <ProposalHeader>Talk to Us</ProposalHeader>
            <ProposalText>After Submitting your request. Our representative will get in touch as soon as possible</ProposalText>
            <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact" />
                <FormWrap>
                    <label htmlFor="name">
                        <input type="text" placeholder="Enter Your Name" id="name" name="name" />
                    </label>
                    <label htmlFor="email">
                        <input type="email" placeholder="Enter Your Email" id="email" name="email" />
                    </label>
                    {/* <label htmlFor="Contact Number">
                        <input type="text" placeholder="Contact Number" id="contact" name="contact" />
                    </label> */}
                    {/* <label htmlFor="budget">
                        <input type="text" placeholder="Approximate Budget" id="budget" name="budget" />
                    </label> */}
                    {/* <label htmlFor="feedback">
                        <input type="text" placeholder="How did you hear about us" id="feedback" name="feedback" />
                    </label> */}
                    <label htmlFor="description">
                        <input type="textarea" placeholder="Message" id="description" name="description" />
                    </label>
                    <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Send</Button>
                </FormWrap>
            </form>

        </ProposalContainer>
    )
}

export default Request


const ProposalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    background: transparent;
    color: #000;
    width: 100%;       
`

const ProposalHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem; 
    align-self: center;    
`

const ProposalText = styled.p`
        text-align: center;
        font-size: clamp(1rem,2vw, 1.25rem); 
        margin-bottom: 2rem;  
        align-self: center;
        width: 100%; 
    `

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
   

 input {
     padding: 1rem 1,5rem;
     outline: none;
     width: 100%;
     height: 50px;
     border-radius: 20px;
     border: 2px solid #71C4DE;
     margin-bottom: 1rem;
     text-align: center;
 }

 input[type=textarea] {
    height: 200px;
 }
`
